import { Injectable, inject } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CookieService } from 'ngx-unificator/services';
import { map, tap } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import { EnvironmentService } from './environment.service';
import { DOCUMENT } from '@angular/common';
import { WindowService } from './window.service';
import { cssLineralGradientToJSON, generateRegExp } from '../helpers/color';

/**
 * Name of cookie where current theme value is stored
 * use 'theme-dark' name instead 'theme' to support users previous cookie value (when we had only 2 themes)
 */
export const THEME_COOKIE = 'theme';

/**
 * Locales with only one theme - blue
 */
export const AU_THEME_LOCALES = 'au';
export const HOLLAND_THEME_LOCALE = 'nl';
export const DE_CA_THEME_LOCALE = ['de', 'ca'];
export const BR_MX_ZAF_THEME_LOCALE = ['br', 'mx', 'zaf'];
export const INDIA_THEME_LOCALE = 'in';
export const INDONESIA_THEME_LOCALE = 'id';
export const VIP_THEME = 'vip';

/**
 * Available themes
 */
export enum THEME {
  DEFAULT_DARK = 0,
  HOLLAND = 1,
  AU = 2,
  DE_CA = 3,
  BR_MX_ZAF = 4,
  INDIA = 5,
  INDONESIA = 6,
  VIP = 7,
}


/**
 * Global themes classes strings
 */
export const GLOBAL_CLASSES = {
  DEFAULT_DARK: 'default-dark',
  HOLLAND: 'holland',
  AU: 'au',
  DE_CA: 'de-ca',
  BR_MX_ZAF: 'br-mx-zaf',
  INDIA: 'india',
  INDONESIA: 'indonesia',
  VIP: 'vip',
};

export interface StopColor {
  color?: string;
  position?: string;
}

export interface ConvertedLinerGradient {
  original?: string;
  colorStopList?: StopColor[];
  line?: string;
  angle?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _cookie = inject(CookieService);
  private _env = inject(EnvironmentService);
  private _platform = inject(PlatformService);
  private _window = inject(WindowService);
  private _document = inject<Document>(DOCUMENT);

  /**
   * BehaviorSubject which hold current theme value
   */
  private _changeTheme$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /**
   * Current theme
   *
   * @private
   */
  private _currentTheme: THEME;

  /**
   * Access to _changeTheme$
   */
  public changeTheme$: Observable<any> = this._changeTheme$.asObservable();

  private _convertedLinerGradient: ConvertedLinerGradient = null;
  private _regExpLib = generateRegExp();

  constructor() {
    this._resolveDefaultTheme$().pipe(
      tap((theme) => this.setTheme(theme))
    ).subscribe();
  }

  /**
   * Access to current theme value
   */
  get currentTheme(): THEME {
    return this._currentTheme;
  }

  /**
   * Access to available themes
   */
  get themes() {
    return THEME;
  }

  public get convertedLinerGradient(): ConvertedLinerGradient {
    return this._convertedLinerGradient;
  }

  /**
   * Use provided theme
   * @param theme
   * @param background
   */
  public setTheme(theme: THEME, background?: any) {
    if (theme !== this.currentTheme) {
      this._changeTheme$.next(theme);
      this._currentTheme = theme;
    }
  }

  /**
   * Resolve user default theme
   */
  private _resolveDefaultTheme$(): Observable<THEME> {
    return this._env.env$.pipe(
      map(() => {
        let userTheme;
        if (this._cookie.check(THEME_COOKIE)) {
          userTheme = parseInt(this._cookie.get(THEME_COOKIE), 10);
        } else {
          userTheme = THEME.DEFAULT_DARK;
          switch (true) {
            case AU_THEME_LOCALES.includes(this._env.env.country.short):
              userTheme = THEME.AU;
              break;
            case HOLLAND_THEME_LOCALE.includes(this._env.env.country.short):
              userTheme = THEME.HOLLAND;
              break;
            case DE_CA_THEME_LOCALE.includes(this._env.env.country.short):
              userTheme = THEME.DE_CA;
              break;
            case BR_MX_ZAF_THEME_LOCALE.includes(this._env.env.country.short):
              userTheme = THEME.BR_MX_ZAF;
              break;
            case INDIA_THEME_LOCALE.includes(this._env.env.country.short):
              userTheme = THEME.INDIA;
              break;
            case INDONESIA_THEME_LOCALE.includes(this._env.env.country.short):
              userTheme = THEME.INDONESIA;
              break;
          }
        }
        return userTheme;
      })
    );
  }

  /**
   * Current theme resolver
   */
  public resolveTheme$() {
    this.changeTheme$.pipe(
      tap((theme) => {
        this._document.documentElement.classList.add('theme-transition');
        switch (Number(theme)) {
          case this.themes.DEFAULT_DARK:
            this._addGlobalClass(GLOBAL_CLASSES.DEFAULT_DARK);
            break;
          case this.themes.HOLLAND:
            this._addGlobalClass(GLOBAL_CLASSES.HOLLAND);
            break;
          case this.themes.AU:
            this._addGlobalClass(GLOBAL_CLASSES.AU);
            break;
          case this.themes.DE_CA:
            this._addGlobalClass(GLOBAL_CLASSES.DE_CA);
            break;
          case this.themes.BR_MX_ZAF:
            this._addGlobalClass(GLOBAL_CLASSES.BR_MX_ZAF);
            break;
          case this.themes.INDIA:
            this._addGlobalClass(GLOBAL_CLASSES.INDIA);
            break;
          case this.themes.INDONESIA:
            this._addGlobalClass(GLOBAL_CLASSES.INDONESIA);
            break;
          case this.themes.VIP:
            this._addGlobalClass(GLOBAL_CLASSES.VIP);
            break;
        }
        setTimeout(() => {
          this._document.documentElement.classList.remove('theme-transition');
          const cssStyle = this._window.nativeWindow.getComputedStyle(this._document.documentElement).getPropertyValue('--color-gradient');
          this._convertedLinerGradient = cssLineralGradientToJSON(this._regExpLib, cssStyle);
        }, 1500);
        if (this._platform.isBrowser) {
          this._cookie.set(THEME_COOKIE, theme.toString(), 999, '/', (window as any).location.hostname);
        }
      })
    ).subscribe();
  }

  /**
   * Add global class and remove previous classes
   * @param globalClass
   * @private
   */
  private _addGlobalClass(globalClass: string) {
    this._document.documentElement.classList.add(globalClass);
    this._filterGlobalClasses(globalClass);
  }

  /**
   * Remove and filter previous classes
   * @param key
   * @private
   */
  private _filterGlobalClasses(key: string) {
    Object.values(GLOBAL_CLASSES).forEach(item => {
      if (key !== item) {
        this._document.documentElement.classList.remove(item);
      }
    });
  }
}
